import React from 'react';
import {Menu} from "antd";
import {useHistory} from "react-router-dom";
const {SubMenu} = Menu

export default function MenuWithRoute({route}:{route:RouteConfig}) {
    const history = useHistory()
    const handleClick = function({ item, key, keyPath, selectedKeys, domEvent }) {
        keyPath.reverse()
        let url = ''
        for (const path of keyPath) {
            url+='/'
            url+=path
        }
        history.push(url)
    }
    const renderMenu = (route:RouteConfig) => {
        if (route.notInMenu) {
            return
        } else if (route.redirect) { // case submenu
            console.log(route)
            return (
                <SubMenu  key={route.name} title={route.title} icon={route.icon?<route.icon /> : <></>}>
                    {route.children!.map(renderMenu)}
                </SubMenu>
            )
        } else { //case item
            console.log(route)
            return (
                <Menu.Item className='menu-lis' key={route.name} icon={route.icon?<route.icon /> : <></>}>{route.title}</Menu.Item>
            )
        }
    }

    return (
        <Menu onSelect={handleClick} mode="inline" style={{ height: '100%', borderRight: 0 }}>
            {route.children!.map(renderMenu)}
        </Menu>
    );
}