import {createSlice} from "@reduxjs/toolkit";

interface LoginState {
    isLogin:number
}
const initialState:LoginState = {
    isLogin:0
}
export const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers: {
        postlogin: state => {
            state.isLogin = 2
        },
        login: state => {
            state.isLogin = 1
        },
        logout: state => {
            state.isLogin = 0
        }
    }
})

export const { login, logout, postlogin} = loginSlice.actions

export default loginSlice.reducer
