import * as dd from 'dingtalk-jsapi';
import {dingLogin} from "./dpapi";
export function dingReady(){
    return new Promise((resolve,reject)=>{
        // @ts-ignore
        dd.ready(resolve)
    })
}

export function dingAuthCode(corpId:string):Promise<{code:string}>{
    return new Promise((resolve,reject)=>{
        dd.runtime.permission.requestAuthCode({
            corpId,
            onSuccess: resolve,
            onFail : reject
        } as any);
    })
}

export async function dingAuth(){
    let res:any = null;
    let wd = window as unknown as { $token:string };
    if (process.env.REACT_APP_ACESS_TOKEN) {
        wd.$token = process.env.REACT_APP_ACESS_TOKEN
        return;
    } else if (process.env.REACT_APP_USER_ID) {
        res = await dingLogin('');
    } else {
        await dingReady();
        const authCodeRes = await dingAuthCode(process.env.REACT_APP_CORP_ID!);
        const code = authCodeRes.code;
        res = await dingLogin(code);
    }
    console.log('ding login')
    console.log(res)
    if (res) {
        wd.$token = res.obj;
    }
}
