import {getUserRoleList} from "./dpapi";

export async function prepareRoleAuth(){
    let wd = window as unknown as {$roleSet:Record<string, boolean>|undefined}
    if (!wd.$roleSet) {
        wd.$roleSet = {}
        const res = await getUserRoleList()
        res.list.forEach(role=>{wd.$roleSet![role] = true})
    }
}
export async function userHasRole(role){
    await prepareRoleAuth();
    let wd = window as unknown as {$roleSet:Record<string, boolean>|undefined}
    return wd.$roleSet![role] ?? false
}
