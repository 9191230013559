import {
    AppstoreOutlined,
    PieChartOutlined,
    DesktopOutlined,
    CloudSyncOutlined,
    ContainerOutlined,
    ExclamationCircleOutlined,
    ShopOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
export const configBeforeLogin:RouteConfig = {
    path:'/',
    name:'root',
    title:'',
    redirect:'/login',
    children:[
        {
            path:'/login',
            name:'login',
            title:'登录页',
            component:()=>import('../views/before-login/Login')
        }
    ]
}

export const configAfterLogin:RouteConfig = {
    path:'/',
    title:'',
    name:'root',
    redirect:'/welcome',
    children:[
        {
            path:'/welcome',
            name:'welcome',
            title:'欢迎页面',
            icon: AppstoreOutlined,
            component:()=>import('../views/welcome/Index')
        },
        {
            path:'/clearing',
            name:'clearing',
            title:'财务新零售核销',
            icon:ContainerOutlined,
            redirect:'/clearing/order-clearing',
            children:[
                {
                    path:'/clearing/order-clearing',
                    name:'order-clearing',
                    title:'新零售订单核对',
                    icon:PieChartOutlined,
                    component:()=>import('../views/clearing/order-clearing/Index')
                },
                {
                    path:'/clearing/order-clearing-update',
                    name:'order-clearing-update',
                    title:'',
                    notInMenu:true,
                    component:()=>import('../views/clearing/order-clearing/Update')
                },
                {
                    path:'/clearing/admin-member-list',
                    name:'admin-member-list',
                    title:'店铺人员维护',
                    icon:CloudSyncOutlined,
                    component:()=>import('../views/clearing/admin-member/Index')
                },
                {
                    path:'/clearing/admin-member-add',
                    name:'admin-member-add',
                    title:'店铺人员添加',
                    notInMenu:true,
                    component:()=>import('../views/clearing/admin-member/Add')
                },
                {
                    path:'/clearing/admin-member-update',
                    name:'admin-member-update',
                    title:'店铺人员更新',
                    notInMenu:true,
                    component:()=>import('../views/clearing/admin-member/Update')
                },
                {
                    path:'/clearing/cash-flow',
                    name:'cash-flow',
                    title:'银行流水',
                    icon:DesktopOutlined,
                    component:()=>import('../views/clearing/cash-flow/Index')
                },
                {
                    path:'/clearing/action-log',
                    name:'action-log',
                    title:'行为日志',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/clearing/action-log/Index')
                }
            ]
        },
        {
            path:'/qh5',
            name:'qh5',
            title:'体质测试h5',
            icon: AppstoreOutlined,
            redirect:'/qh5/stat',
            children:[
                {
                    path:'/qh5/stat',
                    name:'stat',
                    title:'统计',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/questionh5/Index')
                }
            ]
        },
        //1备案门店 2 签到结果
        {
            path:'/sign',
            name:'sign',
            title:'OTC签到',
            icon: AppstoreOutlined,
            redirect:'/sign/record-store',
            children:[
                {
                    path:'/sign/store',
                    name:'store',
                    title:'办事处维护',
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/store/Index')
                },
                {
                    path:'/sign/store-add',
                    name:'store-add',
                    title:'办事处添加',
                    notInMenu: true,
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/store/Add')
                },
                {
                    path:'/sign/record-store',
                    name:'record-store',
                    title:'备案门店',
                    icon:ShopOutlined,
                    component:()=>import('../views/sign/record-store/Index')
                },
                {
                    path:'/sign/sign-results',
                    name:'sign-results',
                    title:'签到结果',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-results/Index')
                },
                {
                    path:'/sign/geo-util',
                    name:'geo-util',
                    title:'经纬度工具',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/geoutil/Index')
                }
                ,{
                    path:'/sign/sign-statistica',
                    name:'sign-statistica',
                    title:'OTC签到汇总统计表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-statistica/Index')
                }
                ,{
                    path:'/sign/sign-detailTable',
                    name:'sign-detailTable',
                    title:'OTC签到明细表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/sign/sign-detailTable/Index')
                }
            ]
        },
        {//商务签到后台
            path:'/channel',
            name:'channel',
            title:'商务签到',
            icon: AppstoreOutlined,
            redirect:'/channel/record-channel',
            children:[
                {
                    path:'/channel/channel-record',
                    name:'channel-record',
                    title:'日明细',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-record/Index')
                },
                {
                    path:'/channel/channel-week',
                    name:'channel-week',//
                    title:'周报表',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-week/Index')
                },
                {
                    path:'/channel/channel-month',
                    name:'channel-month',
                    title:'月报表',
                    icon:ShopOutlined,
                    component:()=>import('../views/channel/channel-month/Index')
                },
                {
                    path:'/channel/channel-level',
                    name:'channel-level',
                    title:'备案商业信息',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-level/Index')
                },
                {
                    path:'/channel/channel-mapRelation',
                    name:'channel-mapRelation',
                    title:'各省区人员映射维护表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-mapRelation/Index')
                },
                {
                    path:'/channel/channel-mapRelation-add',
                    name:'channel-mapRelation-add',
                    title:'新增',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-mapRelation/Add')
                },
                {
                    path:'/channel/channel-mapRelation-update',
                    name:'channel-mapRelation-update',
                    title:'修改',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-mapRelation/Update')
                },
                {
                    path:'/channel/channel-recordCompany',
                    name:'channel-recordCompany',
                    title:'备案商业公司',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-recordCompany/Index')
                },
                {
                    path:'/channel/channel-recordAreaList',
                    name:'channel-recordAreaList',
                    title:'备案区域列表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/channel/channel-recordAreaList/Index')
                },{
                    path:'/channel/channel-recordArea-update',
                    name:'channel-recordArea-update',
                    title:'备案区域修改',
                    notInMenu:true,
                    component:()=>import('../views/channel/channel-recordAreaList/Update')
                },
            ]
        },
        {
            path:'/phototherapy',
            name:'phototherapy',
            title:'光疗签到',
            icon: AppstoreOutlined,
            redirect:'/phototherapy/phototherapy-record',
            children:[

                {
                    path:'/phototherapy/phototherapy-record',
                    name:'phototherapy-record',
                    title:'签到结果',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/phototherapy/phototherapy-record/Index')
                }
                ,{
                    path:'/phototherapy/p-statistica',
                    name:'p-statistica',
                    title:'光疗签到汇总统计表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/phototherapy/p-statistica/Index')
                }
                ,{
                    path:'/phototherapy/p-detailTable',
                    name:'p-detailTable',
                    title:'光疗签到明细表',
                    icon:UnorderedListOutlined,
                    component:()=>import('../views/phototherapy/p-detailTable/Index')
                }
            ]
        },
        {
            path:'/guangliaoi18n',
            name:'guangliaoi18n',
            title:'光疗双语言',
            icon: AppstoreOutlined,
            redirect:'/guangliaoi18n/navigation-column',
            children:[
                {
                    path:'/guangliaoi18n/navigation-column',
                    name:'navigation-column',
                    title:'导航栏目管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/Index')
                },
                // navigation-column-en
                {
                    path:'/guangliaoi18n/navigation-column-en',
                    name:'navigation-column-en',
                    title:'导航栏目管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/IndexEn')
                },
                {
                    path:'/guangliaoi18n/navigation-column-update',
                    name:'navigation-column-update',
                    title:'导航栏目更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/Update')
                },
                // navigation-column-update-en
                {
                    path:'/guangliaoi18n/navigation-column-update-en',
                    name:'navigation-column-update-en',
                    title:'导航栏目更新英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/UpdateEn')
                },
                {
                    path:'/guangliaoi18n/navigation-column-add',
                    name:'navigation-column-add',
                    title:'导航栏目添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/Add')
                },
                // navigation-column-add-en
                {
                    path:'/guangliaoi18n/navigation-column-add-en',
                    name:'navigation-column-add-en',
                    title:'导航栏目添加英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/navigation-column/AddEn')
                }
                ,{
                    path:'/guangliaoi18n/development-history',
                    name:'development-history',
                    title:'发展历程管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/Index')
                },
                //development-history-en
                {
                    path:'/guangliaoi18n/development-history-en',
                    name:'development-history-en',
                    title:'发展历程管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/IndexEn')
                },
                //development-history-add
                {
                    path:'/guangliaoi18n/development-history-add',
                    name:'development-history-add',
                    title:'发展历程添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/Add')
                },
                // development-history-add-en
                {
                    path:'/guangliaoi18n/development-history-add-en',
                    name:'development-history-add-en',
                    title:'发展历程添加英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/AddEn')
                },
                //development-history-update
                {
                    path:'/guangliaoi18n/development-history-update',
                    name:'development-history-update',
                    title:'发展历程修改',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/Update')
                },
                //development-history-update-en
                {
                    path:'/guangliaoi18n/development-history-update-en',
                    name:'development-history-update-en',
                    title:'发展历程修改英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/development-history/UpdateEn')
                },
                {
                    path:'/guangliaoi18n/news-information',
                    name:'news-information',
                    title:'新闻资讯管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/Index')
                },
                //news-information-en
                {
                    path:'/guangliaoi18n/news-information-en',
                    name:'news-information-en',
                    title:'新闻资讯管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/IndexEn')
                },
                //news-information-add
                {
                    path:'/guangliaoi18n/news-information-add',
                    name:'news-information-add',
                    title:'新闻资讯添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/Add')
                },
                //news-information-add-en
                {
                    path:'/guangliaoi18n/news-information-add-en',
                    name:'news-information-add-en',
                    title:'新闻资讯添加英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/AddEn')
                },
                //news-information-update
                {
                    path:'/guangliaoi18n/news-information-update',
                    name:'news-information-update',
                    title:'新闻资讯修改',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/Update')
                },
                // news-information-update-en
                {
                    path:'/guangliaoi18n/news-information-update-en',
                    name:'news-information-update-en',
                    title:'新闻资讯修改英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/UpdateEn')
                }
                ,{
                    path:'/guangliaoi18n/single-page',
                    name:'single-page',
                    title:'单页管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/single-page/Index')
                },
                //single-page-en
                {
                    path:'/guangliaoi18n/single-page-en',
                    name:'single-page-en',
                    title:'单页管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/single-page/IndexEn')
                },

                //single-page-update
                {
                    path:'/guangliaoi18n/single-page-update',
                    name:'single-page-update',
                    title:'单页修改',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/single-page/Update')
                },
                //single-page-update-en
                {
                    path:'/guangliaoi18n/single-page-update-en',
                    name:'single-page-update-en',
                    title:'单页修改英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/single-page/UpdateEn')
                }
                // banner-data
                ,{
                    path:'/guangliaoi18n/banner-data',
                    name:'banner-data',
                    title:'banner管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/Index')
                }
                // banner-data-en
                ,{
                    path:'/guangliaoi18n/banner-data-en',
                    name:'banner-data-en',
                    title:'banner管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/IndexEn')
                }
                // banner-data-add
                ,{
                    path:'/guangliaoi18n/banner-data-add',
                    name:'banner-data-add',
                    title:'banner添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/Add')
                }
                // banner-data-add-en
                ,{
                    path:'/guangliaoi18n/banner-data-add-en',
                    name:'banner-data-add-en',
                    title:'banner添加英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/AddEn')
                }
                // banner-data-update
                ,{
                    path:'/guangliaoi18n/banner-data-update',
                    name:'banner-data-update',
                    title:'banner修改',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/Update')
                }
                // banner-data-update-en
                ,{
                    path:'/guangliaoi18n/banner-data-update-en',
                    name:'banner-data-update-en',
                    title:'banner修改英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data/UpdateEn')
                }
                // banner-data-logo
                ,{
                    path:'/guangliaoi18n/banner-data-logo',
                    name:'banner-data-logo',
                    title:'logo管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/Index')
                }
                // banner-data-logo-en
                ,{
                    path:'/guangliaoi18n/banner-data-logo-en',
                    name:'banner-data-logo-en',
                    title:'logo管理英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/IndexEn')
                }
                // banner-data-logo-add
                ,{
                    path:'/guangliaoi18n/banner-data-logo-add',
                    name:'banner-data-logo-add',
                    title:'logo添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/Add')
                }
                // banner-data-logo-add-en
                ,{
                    path:'/guangliaoi18n/banner-data-logo-add-en',
                    name:'banner-data-logo-add-en',
                    title:'logo添加英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/AddEn')
                }
                // banner-data-logo-update
                ,{
                    path:'/guangliaoi18n/banner-data-logo-update',
                    name:'banner-data-logo-update',
                    title:'logo修改',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/Update')
                }
                // banner-data-logo-update-en
                ,{
                    path:'/guangliaoi18n/banner-data-logo-update-en',
                    name:'banner-data-logo-update-en',
                    title:'logo修改英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-logo/UpdateEn')
                }
                // banner-data-3
                ,{
                    path:'/guangliaoi18n/banner-data-3',
                    name:'banner-data-3',
                    title:'banner管理公司介绍',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/Index')
                }
                // banner-data-3-en
                ,{
                    path:'/guangliaoi18n/banner-data-3-en',
                    name:'banner-data-3-en',
                    title:'banner管理公司介绍英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/IndexEn')
                }
                // banner-data-3-add
                ,{
                    path:'/guangliaoi18n/banner-data-3-add',
                    name:'banner-data-3-add',
                    title:'banner添加公司介绍',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/Add')
                }
                // banner-data-3-add-en
                ,{
                    path:'/guangliaoi18n/banner-data-3-add-en',
                    name:'banner-data-3-add-en',
                    title:'banner添加公司介绍英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/AddEn')
                }
                // banner-data-3-update
                ,{
                    path:'/guangliaoi18n/banner-data-3-update',
                    name:'banner-data-3-update',
                    title:'banner修改公司介绍',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/Update')
                }
                // banner-data-3-update-en
                ,{
                    path:'/guangliaoi18n/banner-data-3-update-en',
                    name:'banner-data-3-update-en',
                    title:'banner修改公司介绍英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-3/UpdateEn')
                }

                // banner-data-4
                ,{
                    path:'/guangliaoi18n/banner-data-4',
                    name:'banner-data-4',
                    title:'banner管理总裁寄语',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/Index')
                }
                // banner-data-4-en
                ,{
                    path:'/guangliaoi18n/banner-data-4-en',
                    name:'banner-data-4-en',
                    title:'banner管理总裁寄语英文版',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/IndexEn')
                }
                // banner-data-4-add
                ,{
                    path:'/guangliaoi18n/banner-data-4-add',
                    name:'banner-data-4-add',
                    title:'banner添加总裁寄语',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/Add')
                }
                // banner-data-4-add-en
                ,{
                    path:'/guangliaoi18n/banner-data-4-add-en',
                    name:'banner-data-4-add-en',
                    title:'banner添加总裁寄语英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/AddEn')
                }
                // banner-data-4-update
                ,{
                    path:'/guangliaoi18n/banner-data-4-update',
                    name:'banner-data-4-update',
                    title:'banner修改总裁寄语',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/Update')
                }
                // banner-data-4-update-en
                ,{
                    path:'/guangliaoi18n/banner-data-4-update-en',
                    name:'banner-data-4-update-en',
                    title:'banner修改总裁寄语英文版',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/banner-data-4/UpdateEn')
                }
                // image-swap
                ,{
                    path:'/guangliaoi18n/image-swap',
                    name:'image-swap',
                    title:'图片替换',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/utility/ImageSwap')
                }

                // pageview
                ,{
                   path: '/guangliaoi18n/pageview',
                     name: 'pageview',
                        title: '页面访问量',
                        icon: ExclamationCircleOutlined,
                     component: () => import('../views/guangliaoi18n/pageview/Index')
                },
// Unique Visitors by IP
                {
                    path: '/guangliaoi18n/pageview-ip',
                    name: 'pageview-ip',
                    title: '唯一访客量 (基于IP)',
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaoi18n/pageview/Ip'), // Point to the IP-based dashboard component
                },
// Unique Visitors by Session
                {
                    path: '/guangliaoi18n/pageview-session',
                    name: 'pageview-session',
                    title: '唯一访客量 (基于Session)',
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaoi18n/pageview/Session'), // Point to the session-based dashboard component
                },
                //单页、发展历程、新闻资讯、资质证书
                /*
                ,{
                    path:'/guangliaoi18n/single-page',
                    name:'single-page',
                    title:'单页管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/single-page/Index')
                }

                ,{
                    path:'/guangliaoi18n/news-information',
                    name:'news-information',
                    title:'新闻资讯管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/news-information/Index')
                }
                ,{
                    path:'/guangliaoi18n/qualification-certificate',
                    name:'qualification-certificate',
                    title:'资质证书管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaoi18n/qualification-certificate/Index')
                }

                 */
            ]
        },
        //
        {
            path:'/guangliaodevice',
            name:'guangliaodevice',
            title:'光疗设备',
            icon: AppstoreOutlined,
            redirect:'/guangliaodevice/model',
            children:[
                {
                    path:'/guangliaodevice/model',
                    name:'model',
                    title:'设备型号管理',
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/Index')
                },

                {
                    path:'/guangliaodevice/model-update',
                    name:'model-update',
                    title:'设备型号更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/Update')
                },
                // add
                {
                    path:'/guangliaodevice/model-add',
                    name:'model-add',
                    title:'设备型号添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/Add')
                },
                // add steps
                {
                    path:'/guangliaodevice/model-add-steps-0',
                    name:'model-add-steps-0',
                    title:'设备基础信息添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/AddSteps0')
                },
                {
                    path:'/guangliaodevice/model-add-steps-1',
                    name:'model-add-steps-1',
                    title:'设备蓝牙信息添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/AddSteps1')
                },
                // add steps 2, 图片信息
                {
                    path:'/guangliaodevice/model-add-steps-2',
                    name:'model-add-steps-2',
                    title:'设备图片信息添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/AddSteps2')
                },
                // add steps 3, 设备模式
                {
                    path:'/guangliaodevice/model-add-steps-3',
                    name:'model-add-steps-3',
                    title:'设备模式配置',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/AddSteps3')
                },
                // add steps 4, 首页状态映射
                {
                    path:'/guangliaodevice/model-add-steps-4',
                    name:'model-add-steps-4',
                    title:'设备首页状态映射管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/IndexSteps4')
                },
                {
                    path:'/guangliaodevice/model-add-steps-4-add',
                    name:'model-add-steps-4-add',
                    title:'设备首页状态映射添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/AddSteps4')
                },
                {
                    path:'/guangliaodevice/model-add-steps-4-edit',
                    name:'model-add-steps-4-edit',
                    title:'设备首页状态映射编辑',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/AddSteps4')
                },
                // add steps 5, 操作页状态映射
                {
                    path:'/guangliaodevice/model-add-steps-5',
                    name:'model-add-steps-5',
                    title:'设备操作页状态映射管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/IndexSteps5')
                },
                {
                    path:'/guangliaodevice/model-add-steps-5-add',
                    name:'model-add-steps-5-add',
                    title:'设备操作页状态映射添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/AddSteps5')
                },
                {
                    path:'/guangliaodevice/model-add-steps-5-edit',
                    name:'model-add-steps-5-edit',
                    title:'设备操作页状态映射编辑',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/AddSteps5')
                },

                // add steps 6, 设备模式
                {
                    path:'/guangliaodevice/model-add-steps-6',
                    name:'model-add-steps-6',
                    title:'设备模式配置',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/mode/IndexSteps6')
                },
                {
                    path:'/guangliaodevice/model-add-steps-6-add',
                    name:'model-add-steps-6-add',
                    title:'设备模式添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/mode/AddSteps6')
                },
                // add steps 6-payload, 设备指令模板
                {
                    path:'/guangliaodevice/model-add-steps-6-payload',
                    name:'model-add-steps-6-payload',
                    title:'设备指令模板管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/IndexSteps6')
                },
                {
                    path:'/guangliaodevice/model-add-steps-6-payload-add',
                    name:'model-add-steps-6-payload-add',
                    title:'设备指令模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/AddSteps6')
                },
                {
                    path:'/guangliaodevice/model-add-steps-6-payload-update',
                    name:'model-add-steps-7-update',
                    title:'设备指令模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/AddSteps6')
                },
                // add steps 6, sub page of payload, rule, 设备指令载荷转换规则
                {
                    path:'/guangliaodevice/model-add-steps-6-payload-rule',
                    name:'model-add-steps-6-payload-rule',
                    title:'设备指令载荷转换规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/IndexSteps6')
                },
                {
                    path:'/guangliaodevice/model-add-steps-6-payload-rule-add',
                    name:'model-add-steps-6-payload-rule-add',
                    title:'设备指令载荷转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/AddSteps6')
                },
                {
                    path:'/guangliaodevice/model-add-steps-6-payload-rule-update',
                    name:'model-add-steps-6-payload-rule-update',
                    title:'设备指令载荷转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/AddSteps6')
                },

                // add steps 7, 心跳模板
                {
                    path:'/guangliaodevice/model-add-steps-7',
                    name:'model-add-steps-7',
                    title:'设备心跳模板管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/IndexSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-add',
                    name:'model-add-steps-7-add',
                    title:'设备心跳模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/AddSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-update',
                    name:'model-add-steps-8-update',
                    title:'设备心跳模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/AddSteps7')
                },
                // add steps 7, sub page, extract-rule
                {
                    path:'/guangliaodevice/model-add-steps-7-extract-rule',
                    name:'model-add-steps-7-extract-rule',
                    title:'设备心跳提取规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/IndexSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-extract-rule-add',
                    name:'model-add-steps-7-extract-rule-add',
                    title:'设备心跳提取规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/AddSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-extract-rule-update',
                    name:'model-add-steps-7-extract-rule-update',
                    title:'设备心跳提取规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/AddSteps7')
                },
                // add steps 7, sub page, transform-rule
                {
                    path:'/guangliaodevice/model-add-steps-7-transform-rule',
                    name:'model-add-steps-7-transform-rule',
                    title:'设备心跳转换规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/IndexSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-transform-rule-add',
                    name:'model-add-steps-7-transform-rule-add',
                    title:'设备心跳转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/AddSteps7')
                },
                {
                    path:'/guangliaodevice/model-add-steps-7-transform-rule-update',
                    name:'model-add-steps-7-transform-rule-update',
                    title:'设备心跳转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/AddSteps7')
                },
                // add steps 8, posters
                {
                    path:'/guangliaodevice/model-add-steps-8',
                    name:'model-add-steps8',
                    title:'设备海报管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/posters/IndexSteps8')
                },
                {
                    path:'/guangliaodevice/model-add-steps-8-add',
                    name:'model-add-steps8-add',
                    title:'设备海报添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/posters/AddSteps8')
                },
                // add steps 9, FAQ questions
                {
                    path: '/guangliaodevice/model-add-steps-9',
                    name: 'model-add-steps-9',
                    title: 'FAQ问题管理',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/faqquestion/IndexSteps9')
                },
                {
                    path: '/guangliaodevice/model-add-steps-9-add',
                    name: 'model-add-steps-9-add',
                    title: 'FAQ问题添加',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/faqquestion/AddSteps9')
                },

                // add steps 9, sub page of faq, answer, FAQ 答案
                {
                    path:'/guangliaodevice/model-add-steps-9-faq-answer',
                    name:'model-add-steps-9-faq-answer',
                    title:'FAQ 答案管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/IndexSteps9')
                },
                {
                    path:'/guangliaodevice/model-add-steps-9-faq-answer-add',
                    name:'model-add-steps-9-faq-answer-add',
                    title:'FAQ 答案添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/AddSteps9')
                },
                {
                    path:'/guangliaodevice/model-add-steps-9-faq-answer-update',
                    name:'model-add-steps-9-faq-answer-update',
                    title:'FAQ 答案更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/AddSteps9')
                },

                // add steps 10, save then finish
                {
                    path: '/guangliaodevice/model-add-steps-10',
                    name: 'model-add-steps10',
                    title: '最后一步',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/SaveThenFinish')
                },

                // edit steps 0
                {
                    path:'/guangliaodevice/model-edit-steps-0',
                    name:'model-edit-steps-0',
                    title:'设备基础信息更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/EditSteps0')
                },
                // edit steps 1
                {
                    path:'/guangliaodevice/model-edit-steps-1',
                    name:'model-edit-steps-1',
                    title:'设备蓝牙信息更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/EditSteps1')
                },
                // edit steps 2
                {
                    path:'/guangliaodevice/model-edit-steps-2',
                    name:'model-edit-steps-2',
                    title:'设备图片信息更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/EditSteps2')
                },
                // edit steps 3
                {
                    path:'/guangliaodevice/model-edit-steps-3',
                    name:'model-edit-steps-3',
                    title:'设备模式配置更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/model/EditSteps3')
                },
                // edit steps 4
                {
                    path:'/guangliaodevice/model-edit-steps-4',
                    name:'model-edit-steps-4',
                    title:'设备首页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/EditIndexSteps4')
                },
                // edit steps 4, add
                {
                    path:'/guangliaodevice/model-edit-steps-4-add',
                    name:'model-edit-steps-4-add',
                    title:'设备首页状态映射添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/EditAddSteps4')
                },
                // edit steps 4, update
                {
                    path:'/guangliaodevice/model-edit-steps-4-update',
                    name:'model-edit-steps-4-update',
                    title:'设备首页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/EditEditSteps4')
                },
                // edit steps 5
                {
                    path:'/guangliaodevice/model-edit-steps-5',
                    name:'model-edit-steps-5',
                    title:'设备操作页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/EditIndexSteps5')
                },
                // edit steps 5, add
                {
                    path:'/guangliaodevice/model-edit-steps-5-add',
                    name:'model-edit-steps-5-add',
                    title:'设备操作页状态映射添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/EditAddSteps5')
                },
                // edit steps 5, update
                {
                    path:'/guangliaodevice/model-edit-steps-5-update',
                    name:'model-edit-steps-5-update',
                    title:'设备操作页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/EditEditSteps5')
                },
                // edit steps 6
                {
                    path:'/guangliaodevice/model-edit-steps-6',
                    name:'model-edit-steps-6',
                    title:'设备模式配置更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/mode/EditIndexSteps6')
                },
                // edit steps 6, add
                {
                    path:'/guangliaodevice/model-edit-steps-6-add',
                    name:'model-edit-steps-6-add',
                    title:'设备模式添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/mode/EditAddSteps6')
                },
                // edit steps 6, update
                {
                    path:'/guangliaodevice/model-edit-steps-6-update',
                    name:'model-edit-steps-6-update',
                    title:'设备模式更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/mode/EditEditSteps6')
                },
                // edit steps 6, payload
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload',
                    name:'model-edit-steps-6-payload',
                    title:'设备指令模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/EditIndexSteps6')
                },
                // edit steps 6, payload, add
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload-add',
                    name:'model-edit-steps-6-payload-add',
                    title:'设备指令模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/EditAddSteps6')
                },
                // edit steps 6, payload, update
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload-update',
                    name:'model-edit-steps-6-payload-update',
                    title:'设备指令模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/EditEditSteps6')
                },
                // edit steps 6, payload, rule
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload-rule',
                    name:'model-edit-steps-6-payload-rule',
                    title:'设备指令载荷转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/EditIndexSteps6')
                },
                // edit steps 6, payload, rule, add
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload-rule-add',
                    name:'model-edit-steps-6-payload-rule-add',
                    title:'设备指令载荷转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/EditAddSteps6')
                },
                // edit steps 6, payload, rule, update
                {
                    path:'/guangliaodevice/model-edit-steps-6-payload-rule-update',
                    name:'model-edit-steps-6-payload-rule-update',
                    title:'设备指令载荷转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/EditEditSteps6')
                },
                // edit steps 7
                {
                    path:'/guangliaodevice/model-edit-steps-7',
                    name:'model-edit-steps-7',
                    title:'设备心跳模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/EditIndexSteps7')
                },
                // edit steps 7, add
                {
                    path:'/guangliaodevice/model-edit-steps-7-add',
                    name:'model-edit-steps-7-add',
                    title:'设备心跳模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/EditAddSteps7')
                },
                // edit steps 7, update
                {
                    path:'/guangliaodevice/model-edit-steps-7-update',
                    name:'model-edit-steps-7-update',
                    title:'设备心跳模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/EditEditSteps7')
                },
                // edit steps 7, extract-rule
                {
                    path:'/guangliaodevice/model-edit-steps-7-extract-rule',
                    name:'model-edit-steps-7-extract-rule',
                    title:'设备心跳提取规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/EditIndexSteps7')
                },
                // edit steps 7, extract-rule, add
                {
                    path:'/guangliaodevice/model-edit-steps-7-extract-rule-add',
                    name:'model-edit-steps-7-extract-rule-add',
                    title:'设备心跳提取规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/EditAddSteps7')
                },
                // edit steps 7, extract-rule, update
                {
                    path:'/guangliaodevice/model-edit-steps-7-extract-rule-update',
                    name:'model-edit-steps-7-extract-rule-update',
                    title:'设备心跳提取规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/EditEditSteps7')
                },
                // edit steps 7, transform-rule
                {
                    path:'/guangliaodevice/model-edit-steps-7-transform-rule',
                    name:'model-edit-steps-7-transform-rule',
                    title:'设备心跳转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/EditIndexSteps7')
                },
                // edit steps 7, transform-rule, add
                {
                    path:'/guangliaodevice/model-edit-steps-7-transform-rule-add',
                    name:'model-edit-steps-7-transform-rule-add',
                    title:'设备心跳转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/EditAddSteps7')
                },
                // edit steps 7, transform-rule, update
                {
                    path:'/guangliaodevice/model-edit-steps-7-transform-rule-update',
                    name:'model-edit-steps-7-transform-rule-update',
                    title:'设备心跳转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/EditEditSteps7')
                },
                // edit steps 8, posters
                {
                    path: '/guangliaodevice/model-edit-steps-8',
                    name: 'model-edit-steps8',
                    title: '设备海报更新',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/posters/EditIndexSteps8')
                },
                {
                    path: '/guangliaodevice/model-edit-steps-8-add',
                    name: 'model-edit-steps-8-add',
                    title: '设备海报添加',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/posters/EditAddSteps8')
                },

                // edit steps 9, FAQ questions
                {
                    path: '/guangliaodevice/model-edit-steps-9',
                    name: 'model-edit-steps-9',
                    title: 'FAQ问题更新',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/faqquestion/EditIndexSteps9')
                },
                // edit steps 9, FAQ questions, add
                {
                    path: '/guangliaodevice/model-edit-steps-9-add',
                    name: 'model-edit-steps-9-add',
                    title: 'FAQ问题添加',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/faqquestion/EditAddSteps9')
                },
                // edit steps 9, FAQ questions, update
                {
                    path: '/guangliaodevice/model-edit-steps-9-update',
                    name: 'model-edit-steps-9-update',
                    title: 'FAQ问题更新',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/faqquestion/EditEditSteps9')
                },

                // edit steps 9, faq, answer (listing page)
                {
                    path:'/guangliaodevice/model-edit-steps-9-faq-answer',
                    name:'model-edit-steps-9-faq-answer',
                    title:'FAQ 答案管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/EditIndexSteps9')
                },
                // edit steps 9, faq, answer, add
                {
                    path:'/guangliaodevice/model-edit-steps-9-faq-answer-add',
                    name:'model-edit-steps-9-faq-answer-add',
                    title:'FAQ 答案添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/EditAddSteps9')
                },
                // edit steps 9, faq, answer, update
                {
                    path:'/guangliaodevice/model-edit-steps-9-faq-answer-update',
                    name:'model-edit-steps-9-faq-answer-update',
                    title:'FAQ 答案更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/faqanswer/EditEditSteps9')
                },


                // edit steps 10
                {
                    path: '/guangliaodevice/model-edit-steps-10',
                    name: 'model-edit-steps-10',
                    title: '设备信息概要',
                    notInMenu: true,
                    icon: ExclamationCircleOutlined,
                    component: () => import('../views/guangliaodevice/EditSummary')
                },

                // payloadtemplate
                {
                    path:'/guangliaodevice/payloadtemplate',
                    name:'payloadtemplate',
                    title:'指令模板管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/Index')
                },
                {
                    path:'/guangliaodevice/payloadtemplate-update',
                    name:'payloadtemplate-update',
                    title:'指令模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/Update')
                },
               {
                    path:'/guangliaodevice/payloadtemplate-add',
                    name:'payloadtemplate-add',
                    title:'指令模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTemplate/Add')
                },

                // statusmappinghome
                {
                    path:'/guangliaodevice/statusmappinghome',
                    name:'statusmappinghome',
                    title:'首页状态映射管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/Index')
                },

                {
                    path:'/guangliaodevice/statusmappinghome-update',
                    name:'statusmappinghome-update',
                    title:'首页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingHome/Update')
                },

                // statusmappingoperation
                {
                    path:'/guangliaodevice/statusmappingoperation',
                    name:'statusmappingoperation',
                    title:'操作页状态映射管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/Index')
                },
                {
                    path:'/guangliaodevice/statusmappingoperation-add',
                    name:'statusmappingoperation-add',
                    title:'操作页状态映射添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/Add')
                },
                {
                    path:'/guangliaodevice/statusmappingoperation-update',
                    name:'statusmappingoperation-update',
                    title:'操作页状态映射更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/statusMappingOperation/Update')
                },

                // heartbeattemplate
                {
                    path:'/guangliaodevice/heartbeattemplate',
                    name:'heartbeattemplate',
                    title:'心跳模板管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/Index')
                },
                {
                    path:'/guangliaodevice/heartbeattemplate-add',
                    name:'heartbeattemplate-add',
                    title:'心跳模板添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/Add')
                },
                {
                    path:'/guangliaodevice/heartbeattemplate-update',
                    name:'heartbeattemplate-update',
                    title:'心跳模板更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTemplate/Update')
                },

                // heartbeatextractrule

                {
                    path:'/guangliaodevice/heartbeatextractrule',
                    name:'heartbeatextractrule',
                    title:'心跳提取规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/Index')
                },
                {
                    path:'/guangliaodevice/heartbeatextractrule-add',
                    name:'heartbeatextractrule-add',
                    title:'心跳提取规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/Add')
                },
                {
                    path:'/guangliaodevice/heartbeatextractrule-update',
                    name:'heartbeatextractrule-update',
                    title:'心跳提取规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatExtractRule/Update')
                },

                // heartbeattransformrule
                {
                    path:'/guangliaodevice/heartbeattransformrule',
                    name:'heartbeattransformrule',
                    title:'心跳转换规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/Index')
                },
                {
                    path:'/guangliaodevice/heartbeattransformrule-add',
                    name:'heartbeattransformrule-add',
                    title:'心跳转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/Add')
                },
                {
                    path:'/guangliaodevice/heartbeattransformrule-update',
                    name:'heartbeattransformrule-update',
                    title:'心跳转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/heartbeatTransformRule/Update')
                },

                // payloadtransformrule
                {
                    path:'/guangliaodevice/payloadtransformrule',
                    name:'payloadtransformrule',
                    title:'指令转换规则管理',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/Index')
                },
                {
                    path:'/guangliaodevice/payloadtransformrule-add',
                    name:'payloadtransformrule-add',
                    title:'指令转换规则添加',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/Add')
                },
                {
                    path:'/guangliaodevice/payloadtransformrule-update',
                    name:'payloadtransformrule-update',
                    title:'指令转换规则更新',
                    notInMenu:true,
                    icon:ExclamationCircleOutlined,
                    component:()=>import('../views/guangliaodevice/payloadTransformRule/Update')
                },
            ]
        }


    ]
}
export const configNoop:RouteConfig = {
    path:'/',
    name:'noop',
    title:'',
    children:[]
}
